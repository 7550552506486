import axios from "axios";
import { useEffect, useState } from "react";
import {
  getAllCentersList,
  getAllLocations,
} from "../../services/mykare/centerService";
import {
  getOrigins,
  getPackages,
  getRelation,
} from "../../services/mykare/dataService";
import { getSources, saveEnquiry } from "../../services/mykare/enquiryService";
import * as appConstants from "../../util/AppConstants";
import { saveEventActivityLogs } from "../../util/EventActivity";
import { getAllCountries } from "./../../services/mykare/userService";
import { checkDuplicate } from "../../services/mykare/enquiryService";

function usePopUp(props) {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedTreatment, setselectedTreatment] = useState("");
  const [treatments, setTreatments] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedOrigin, setselectedOrigin] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedCenter, setSelectedCenter] = useState("");
  const [relation, setRelation] = useState([]);
  const [selectedRelation, setSelectedRelation] = useState("");
  const [relationName, setRelationName] = useState(null);
  console.log("relation", relation);
  // const [countryCode, setCountryCode] = useState(null);
  const [countryCode, setCountryCode] = useState({
    id: 11,
    name: "India",
    code: "+91",
  });

  const [origins, setOrigins] = useState([]);
  const [assignedAgent, setAssignedAgent] = useState();
  const [assignedCode, setAssignedCode] = useState();
  const [userData, setUserData] = useState([]);
  const [comment, setComment] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [selectedSource, setSelectedSource] = useState("");
  const [sources, setSources] = useState([]);
  const [referral, setReferral] = useState("");
  const [centers, setCenters] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const loadCountries = () => {
    getAllCountries().then((countryList) => {
      setCountryList(countryList?.data);
    });
  };

  const loadCentersList = () => {
    getAllCentersList().then((data) => {
      setCenters(data?.centers);
    });
  };
  const loadRelation = () => {
    getRelation().then((data) => {
      console.log("relation data", data);
      setRelation(data?.data?.relationResponses);
    });
  };
  useEffect(() => {
    loadTreatments();
    loadCountries();
    loadAllLocations();
    getAgentData();
    getSource();
    loadCentersList();
    loadRelation();
  }, [selectedCenter]);

  const loadTreatments = async () => {
    try {
      getPackages(selectedCenter?.code).then((res) => {
        setTreatments(res.data);
      });
    } catch (error) {}
  };

  const getSource = async () => {
    const response = await getSources();
    if (response.status === 200) {
      if (response.data) {
        setSources(response.data);
      }
    }
  };

  const capitalize = (str) =>
    str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  const handleSearch = (event) =>
    setSearchTerm(event.target.value.toLowerCase());

  const filterList = (list) =>
    list.filter((value) => {
      const name = value?.name?.toLowerCase() || "";
      const valueText = value?.value?.toLowerCase() || "";
      const term = searchTerm.toLowerCase();

      return name.includes(term) || valueText.includes(term);
    });

  const handleToAssigned = (assignTo, assignCode) => {
    setAssignedAgent(assignTo);
    setAssignedCode(assignCode);
  };

  const loadAllLocations = async () => {
    try {
      getAllLocations(selectedCenter?.code).then((data) => {
        setLocations(data);
      });
    } catch (error) {}
  };

  const getAgentData = async () => {
    try {
      const data = await axios.get(appConstants.APP_URL + `/user/all`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      setUserData(data.data.users);

      return data;
    } catch (error) {}
  };

  const saveEnquiryDetails = async () => {
    const role = localStorage.getItem("user-role");
    const checkRole = -["", ""];
    const postData = {
      // enquiryContext: "CENTER",
      // enquiryContextId: 0,
      // isPackage: true,
      name: name,
      sourceId: selectedSource.id,
      packageId: selectedTreatment.id,
      locationId: selectedLocation.id,
      phoneNumber: countryCode?.code + phoneNumber,
      // platform: "MYKARE_WEB",
      assignedTo: assignedCode,
      notes: comment,
      additionalInfo: { notes: comment },
      centerCode: selectedCenter?.code,
      enquiryName: selectedRelation?.id === 1 ? name : relationName,
      relationId: selectedRelation?.id,

      // referredName: referral,
    };

    try {
      if ([29, 18, 17].includes(selectedSource.id)) {
        if (referral && selectedSource && name && phoneNumber) {
          // const duplicatePostData = { phoneNumber: phoneNumber };
          // const duplicateCheckResponse = await checkDuplicate(
          //   duplicatePostData
          // );

          // if (duplicateCheckResponse.status === 409) {
          // alert("User with this phone number already exists");
          // } else {
          const response = await saveEnquiry(postData);
          if (response.status === 200) {
            props.onClicked(false);
            alert("New Lead added successfully");
          } else {
            alert("Please check all status");
          }
          // }
        } else {
          alert("Please fill Referral Name");
        }
      } else if (selectedSource && name && phoneNumber) {
        // const duplicatePostData = { phoneNumber: phoneNumber };
        // const duplicateCheckResponse = await checkDuplicate(duplicatePostData);
        // if (duplicateCheckResponse.status === 409) {
        //   alert("User with this phone number already exists");
        //   return;
        // } else {
        const response = await saveEnquiry(postData);
        if (response.status === 200) {
          props.onClicked(false);
          alert("New Lead added successfully");
        } else {
          alert("Please check all status");
        }
        // }
      } else {
        alert("Please fill all required fields");
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        alert("User with this phone number already exists");
      } else {
        console.error("Error saving enquiry:", error);
      }
    }

    // {
    //   "addedBy": "Test",
    //   "addedByCode": "MYKSA002",
    //   "alternateContact": "7025712278",
    //   "assignedTo": "MYKSA002",
    //   "countryCode": "+91",
    //   "email": "rr@gmail.com",
    //   "gender": "MALE",
    //   "name": "Test",
    //   "notes": "test",
    //   "phoneNumber": "7025712278",
    //   "sourceId": 1
    // }
  };

  return {
    setName,
    setPhoneNumber,
    saveEnquiryDetails,
    name,
    phoneNumber,
    treatments,
    selectedTreatment,
    selectedLocation,
    setselectedTreatment,
    locations,
    setSelectedLocation,
    origins,
    selectedOrigin,
    setselectedOrigin,
    handleToAssigned,
    setAssignedAgent,
    setAssignedCode,
    assignedAgent,
    assignedCode,
    userData,
    countryCode,
    countryList,
    setCountryCode,
    selectedSource,
    setSelectedSource,
    sources,
    setSources,
    setComment,
    setReferral,
    centers,
    setSelectedCenter,
    selectedCenter,
    filterList,
    capitalize,
    handleSearch,
    relation,
    setRelation,
    selectedRelation,
    setSelectedRelation,
    relationName,
    setRelationName,
  };
}

export default usePopUp;
